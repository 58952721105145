.sidebar {
  background-color: rgba(245, 246, 249, 0.95);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-grow: 1;
  /* height: calc(100vh - 60px); */
  width: 240px;
  min-width: 180px;
}

.sidebarContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.upperButtonGroup {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.toolButton {
  width: 100%;
}

.flexSpacer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.lowerButtonGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-bottom: 10px; */
}

.setButton {
  width: 100%;
}
