.dashboardPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 60px);
  min-height: 100px;
}

.mainContent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  color: black;
  align-items: center;
}

.rcaBtnWrapper,
.uploadBtnWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-top: 2vh;
}

.searchFieldWrapper {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 1vh;
}

.fileTableWrapper {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 2vh 0;
  max-height: 75%;
}
