.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-top: 5vh;
}

.form {
  width: 100%;
  margin-top: 10px;
}

.submitButton {
  margin: 10px 0;
}

.title {
  margin-bottom: 20px;
}
