.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f8f9fa;
}

.mainContainer {
  margin: 30px 50px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.navigationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  background-color: #f0f0f0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.navSection {
  width: 15%;
  min-width: 125px;
}

.navItem {
  cursor: pointer;
  margin-bottom: 8px;
  padding: 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.navItem:hover {
  background-color: #e9ecef;
}

.formContainer {
  flex: 1;
  padding: 24px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}
