.compareFilesPage {
    font-family: 'Roboto', sans-serif; /* Use Roboto font */
    display: flex;
    margin: 0;
    padding: 0;
    height: 92vh;
    background-color: #f4f4f4; /* Light background color */
}
.sidebar {
    width: 360px;
    background-color: #fff; /* White sidebar background */
    padding: 8px;
    overflow-y: auto;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Add shadow */
}
.mainContent {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 0px; /* Add padding to main content */
}
.column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 0 2px; /* Add margin between columns */
}
.htmlView {
    flex: 1;
    border-right: 1px solid #ccc;
    border-radius: 5px; /* Add rounded corners */
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Add shadow */
}
.diffList {
    height: 95%;
}
.diffItem {
    padding: 8px; /* Add more padding */
    border-top: 1px solid #dcdcdc;
    background-color: #fff; /* White background for diff items */
    border-radius: 6px; /* Add rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); /* Add shadow */
    margin-top: 8px;
    font-size: 14px;
}
h2 {
    color: #333; /* Darker text color for headings */
}
button {
    background-color: #007bff; /* Blue button background */
    color: #fff; /* White button text */
    padding: 8px 8px;
    margin: 2px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.fileLabel {
    display: inline-block;
    padding: 12px 12px;
    background-color: #ddd;
    border-radius: 4px;
    margin: 4px;
}
.fileLabel:hover {
    background-color: #ddd;
}