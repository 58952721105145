.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainContent {
  display: flex;
  flex-grow: 1;
  background-color: white;
}

.leftArea {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  background-color: #e9e9e9;
  border-right: 1px solid #ccc;
  transition: flex-basis 0.2s ease;
  overflow: auto;
}

.gutterHorizontal {
  min-width: 2px;
  width: 2px;
  cursor: col-resize;
  background-color: #ccc;
  background-image: linear-gradient(to bottom, #e0e0e0, #b0b0b0);
  transition:
    background-color 0.2s,
    width 0.2s;
}

.gutterHorizontal:hover {
  width: 8px;
  background-color: #999;
}

.rightArea {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #666;
  transition: width 0.2s;
  min-width: 31%;
}

.chatArea {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
  min-height: 100px;
}

.promptArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  flex-grow: 1;
}

.messageArea {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-bottom: 1px solid #bbb;
}

.messageList {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 82vh;
  overflow-y: auto;
}

.messageItem {
  display: flex;
  line-height: 1.5;
  border-bottom: 1.5px solid #ccc;
  text-align: left;
  padding: 10px 30px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.messageUser {
  background-color: #fff;
}

.messageBot {
  background-color: #f7f5f5; /* Light grey background for robot messages */
}

.sourceButton {
  margin: 2px;
}

.clearButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0;
}

.clearButton {
  min-width: 64px;
}

.chatInputContainer {
  padding: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin: 0 30px;
  max-height: 16vh;
}

.inputFieldWrapper {
  width: 100%;
  height: 90%;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
