html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

:root {
  --primary-color: #f8f8f8; /* Change primary color */
  /* Define other custom colors */
}
