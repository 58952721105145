.dialogContent {
  width: 100%;
  padding: 20px;
  overflow-x: hidden;
  box-sizing: border-box;
}

.marginTopBottom {
  margin-top: 8px;
  margin-bottom: 8px;
}

.questionListItem {
  display: flex;
  flex-direction: column;
}

.removeIcon {
  align-self: flex-end;
}

.textFieldRow {
  display: flex;
  gap: 16px;
  width: 100%;
  margin: 10px 0px;
}

.supplementRow {
  margin-left: 40px;
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.compactField {
  flex: 1;
}

.titleDescription {
  max-width: 500px;
  margin-bottom: 20px;
}

.addQuestionButton {
  margin-top: 20px;
  justify-content: center;
}
