.riskAnalysisPage {
    display: flex;
    height: 92vh;
}

.sidebar {
    width: 40%;
    background-color: #fff; /* White sidebar background */
    padding: 8px;
    overflow-y: auto;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.mainContent {
    flex-grow: 1;
}

.riskItem {
    padding: 16px; /* Add more padding */
    border-top: 1px solid #dcdcdc;
    background-color: #fff; /* White background for diff items */
    border-radius: 6px; /* Add rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); /* Add shadow */
    margin-top: 8px;
    font-size: 14px;
}