.pageContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.mainContent {
    display: flex;
    flex: 1;
}

.leftArea {
    flex: 1.5;
    overflow: auto;
    border-right: 1px solid #ccc;
}

.gutterHorizontal {
    width: 5px;
    background-color: #ccc;
    cursor: ew-resize;
}

.rightArea {
    flex: 1;
    padding: 16px;
    overflow: auto;
}

.formContainer {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
}