.barContainer {
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 60px;
  overflow: hidden;
}

.toolBar {
  display: flex;
  flex-grow: 1;
  align-items: stretch;
}

.leftIcons {
  display: flex;
  height: 100%;
}

.rightIcons {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.iconButton {
  border-radius: 0;
  height: 100%;
  transition: background-color 0.3s;
  min-width: 60px;
}

.iconButton:hover {
  display: flex;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.08);
}

.logoIcon {
  width: 35px;
  height: auto;
  margin-right: 15px;
}

.homeButton {
  margin-left: 10px;
}

.title {
  display: flex;
  font-weight: bolder;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
}
