.pageContainer {
  background-color: #f5f5f7;
  display: flex;
  height: 100vh;
  flex-grow: 1;
  flex-direction: column;
}

.mainContent {
  display: flex;
  flex-grow: 1;
}

.panel {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.leftPanel,
.rightPanel {
  min-width: 300px;
  display: flex;
  flex-grow: 1;
}

.leftPanel {
  width: 50%;
}

.rightPanel {
  width: 50%;
  max-width: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.templateDisplay {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.controlGroup {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #f9f9fa;
  overflow-x: auto;
}

.selectField,
.downloadField {
  display: flex;
  align-items: center;
  margin: 10px;
}

.select {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 180px;
  padding: 5px 10px;
  margin-right: 5px;
  height: 40px;
}

.button {
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.centered {
  display: flex;
  flex-grow: 1;
  flex-direction: center;
  justify-content: center;
  align-items: center;
}

.tableContainer {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: auto;
  margin: 20px 15px;
  max-height: 80vh;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.table {
  width: 100%;
  border-collapse: collapse;
  overflow-y: scroll;
}

.table th,
.table td {
  border: 1px solid #ccc;
  padding: 12px 15px;
  text-align: left;
  font-size: 14px;
}

.table th {
  background-color: #e7e7e7;
  color: #333;
  font-weight: bold;
}

.table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.table tr:hover {
  background-color: #e9e9e9;
}

.templateDetails {
  margin: 10px 20px;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  border: 1px solid #d0d0d0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 75vh;
}

.templateDetails ol {
  list-style-type: decimal;
  padding-left: 40px;
  margin-top: 10px;
}

.templateDetails li {
  margin-bottom: 5px;
  position: relative;
  line-height: 1.4;
}
