.spinWrapper {
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  padding: 30px;
  text-align: center;
}

.spin {
  color: #6576db;
}
