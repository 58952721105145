.viewContainer {
  display: flex;
  flex-grow: 1;
  margin: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}

.fileName {
  margin: 10px;
  line-height: 1.25;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  text-overflow: ellipsis;
  font-size: 16px;
}
