.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 8px;
  max-height: 60vh;
  overflow-y: hidden;
}

.card {
  width: 100%;
  margin: 8px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  transition: transform 0.3s ease-in-out;
  height: auto;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 600px) {
  .card {
    width: calc(50% - 16px);
  }
}

.cardActionArea {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
}

.cardContent {
  display: flex;
  flex-direction: column;
  padding: 16px;
  flex-grow: 1;
  /* background-color: red; */
}

.titleText {
  font-size: 18px;
  font-weight: bold;
  color: #424242;
  margin-bottom: 10px;
}

.descriptionText {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
