.tableContainer {
  overflow: auto;
}

.tableRow,
.selectedRow {
  height: 20px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  max-height: 20px;
}

.selectedRow {
  background-color: #e3f2fd;
  border: 1px solid #90caf9;
}

.cellCheckBox {
  max-width: 10px;
}

.cellFileIcon {
  max-width: 10px;
}

.cellFileName {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteBtn {
  margin: 0 10px;
}
