.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.form {
  width: 100%;
  margin-top: 1px;
}

.submitButton {
  margin: 24px 0 16px;
}
