.notFoundContainer {
  text-align: center;
  margin-top: 50px;
}

.notFoundTitle {
  font-size: 24px;
  color: #333;
}

.notFoundText {
  font-size: 16px;
  color: #666;
}
